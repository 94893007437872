/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface BackgroundProps extends React.ComponentPropsWithoutRef<'div'> {}

const Background: React.FC<BackgroundProps> = (props) => {
  const styles = css`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: -1;
    svg {
      width: max(2560px, 100vw);
      height: auto;
      display: block;
      overflow: visible;
    }
  `;

  return <div css={styles} {...props} />;
};

export default Background;
