
import {useState, useEffect} from 'react';
import devtoolsDetect from 'devtools-detect';
import { addListener, launch, DevtoolsStatusChecker } from 'devtools-detector';

export function useDevToolsStatus() {
    const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtoolsDetect.isOpen);

    useEffect(() => {
        const handleChange = (event: { detail: { isOpen: boolean | ((prevState: boolean) => boolean); }; }) => {
            setIsDevToolsOpen(event.detail.isOpen);
        };

        window.addEventListener('devtoolschange', handleChange);

        return () => {
            window.removeEventListener('devtoolschange', handleChange);
        };
    }, []);

    return isDevToolsOpen;
}

const DeviceNavigatorInfo = () => {
    const isDevToolsOpen = useDevToolsStatus();

    const [isDevToolsOpen2, setIsDevToolsOpen2] = useState(false);
    useEffect(() => {

// 1. add listener
        addListener(
            (isOpen, detail) => {
                console.log('isOpen', isOpen, detail);
                setIsDevToolsOpen2(isOpen)
            }
        );
// 2. launch detect
        launch();
    }, []);

    /*
    *
    * isDevToolsOpen or isDevToolsOpen2 has the value of true if the devtools is open, device is desktop/PC
    * Platform: need check from physical device
    *  - Windows: Win32
    *  - Mac: MacIntel
    *  - Linux: Linux x86_64
    *  - Android: Linux armv7l
    *  - iOS: iPhone
    *  - iPadOS: iPad
    * UserAgent: ua check
    * */
    const navigator = window.navigator;
    return (
        <div>
            <h1>DeviceNavigatorInfo</h1>
            <p>AppName: {navigator.appName}</p>
            <p>AppVersion: {navigator.appVersion}</p>
            <p>UserAgent: {navigator.userAgent}</p>
            <p>Platform: {navigator.platform}</p>
            {/*<p>Platform2: {navigator.userAgentData.platform}</p>*/}
            <p>HardwareConcurrency: {navigator.hardwareConcurrency}</p>
            <p>IsDevToolsOpen1: {isDevToolsOpen ? "true" : "false"}</p>
            <p>IsDevToolsOpen2: {isDevToolsOpen2 ? "true" : "false"}</p>
            {/*<p>Gpu: {navigator.gpu.wgslLanguageFeatures.size}</p>*/}
        </div>
    );
};
export default  DeviceNavigatorInfo;