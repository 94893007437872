import http from '@/rest/utils/http';
import { ENDPOINTS } from '@/rest/utils/endpoint';

export const submitFeedback = async (data: API.SubmitFeedBackParams) => {
    return await http.post<API.Response<API.SubmitFeedBackResponse>>(ENDPOINTS.SUBMIT_FEEDBACK, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getDropInquiryReasonMapList = async (type: string) => {
    return await http.get<API.Response<API.DropMapListResponse>>(ENDPOINTS.DROP_INQUIRY_REASON_MAP(type));
}

export const getDropInquiryTypeList = async (type: string) => {
    return await http.get<API.Response<API.DropListResponse>>(ENDPOINTS.DROP_INQUIRY_TYPE(type));
}