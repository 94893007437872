import React, { useEffect, useState } from 'react';

// const isDesktop = () => {
//     const hasMouse = matchMedia('(pointer:fine)').matches;
//     return hasMouse;
// };
const DeviceTypeCheckerByMouse = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const checkDeviceType = () => {
            const hasMouse = matchMedia('(pointer:fine)').matches;
            setIsDesktop(hasMouse);
        };

        checkDeviceType();
        window.addEventListener('resize', checkDeviceType);

        return () => {
            window.removeEventListener('resize', checkDeviceType);
        };
    }, []);

    return (
        <div>
            {isDesktop ? 'This device is a desktop or has a mouse.' : 'This device is not a desktop or does not have a mouse.'}
        </div>
    );
};

export default DeviceTypeCheckerByMouse;

