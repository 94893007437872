import * as ToastPrimitive from '@radix-ui/react-toast';
import React from 'react';

export type ToastProps = {

    children?: React.ReactNode;
};
export type HUDProps = {
    open: boolean;
    hide?: () => void;
    title: string;
    content: string;
    children?: React.ReactNode;
};
export interface ToastHandle {
    publish: () => void;
}
export const Toast = React.forwardRef<ToastHandle,ToastProps>((props, forwardedRef) => {
    const { children, ...toastProps } = props;
    const [count, setCount] = React.useState(0);
  
    React.useImperativeHandle(forwardedRef, () => ({
      publish: () => setCount((count) => count + 1),
    }));
  
    return (
      <>
        {Array.from({ length: count }).map((_, index) => (
          <ToastPrimitive.Root key={index} {...toastProps}>
            <ToastPrimitive.Description>{children}</ToastPrimitive.Description>
            <ToastPrimitive.Close>Dismiss</ToastPrimitive.Close>
          </ToastPrimitive.Root>
        ))}
      </>
    );
  });

export const HUD = ({open, title, content, children, ...props}: HUDProps) => (
    <>
    <ToastPrimitive.Root {...props}>
      {title && <ToastPrimitive.Title>{title}</ToastPrimitive.Title>}
      <ToastPrimitive.Description>{content}</ToastPrimitive.Description>
      {children && (
        <ToastPrimitive.Action altText='' asChild>{children}</ToastPrimitive.Action>
      )}
      <ToastPrimitive.Close aria-label="Close">
        <span aria-hidden>×</span>
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
    </>
);