

import { Layout } from '@/components/Layout/layout';
import React, {useEffect} from 'react';
import useSessionStorage from '@/utils/hooks/useSessionStorage';
import { Box, Flex, Grid, Heading, Text, Strong } from "@radix-ui/themes";

const SubmitSuccess: React.FC = () => {


    const [submitResult] = useSessionStorage<API.SubmitFeedBackResponse|null>('complaint-submit-result', null);
    useEffect(() => {

    }, []);

    return (
        <>
            <Layout>
                <Flex width="100%" minHeight="100vh" justify={'center'} align={'center'} py={{ initial: '7', xs: '9', sm: '100px' }}>
                <Box px={{ initial: '4' }}
                     width={{
                    initial: '90%',
                    sm: '80%',
                    xl: '1280px',
                }}
                >
                    <Heading>
                        Thank you for submitting a ticket.

                    </Heading>
                    <Flex direction={"column"} gap={"2"} pt={"8"}>
                        <Text>
                            We have sent the ticket number to your email {submitResult?.targetEmail ? <Strong>{submitResult.targetEmail}</Strong> : "box"}.
                        </Text>
                        <Text>
                            We recommend that you whitelist {submitResult?.serviceEmail ? <Strong>{submitResult.serviceEmail}</Strong> : "service email"} to ensure that our replies don’t land in your SPAM.
                        </Text>
                        <Text>
                            Our customer support team is based in Singapore and is works to assist you from 5:00 PM to 5:00 AM PST, Monday to Friday.
                        </Text><Text>
                        Please allow up to 6-12 hours for a response to your inquiry. Emails sent during non-business hours will be responded to on the next business day.
                    </Text>
                    </Flex>
                </Box>
                </Flex>
  </Layout>
        </>
    );
};
export default SubmitSuccess;