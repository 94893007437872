/** @jsxImportSource @emotion/react */
import * as Toast from '@radix-ui/react-toast';
import { css } from '@emotion/react';
import { useState } from 'react';

// 自定义样式
const toastStyles = (theme: string) => css`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${theme === 'dark' ? '#333' : '#fff'};
  color: ${theme === 'dark' ? '#fff' : '#000'};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  width: 320px;
  font-size: 14px;
  z-index: 1000;

  @media (max-width: 480px) {
    width: 100%;
    left: 0;
    transform: none;
  }
`;

const ToastComponent = () => {
  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState('light'); // 可以通过主题切换控制

  return (
    <>
      <button onClick={() => setOpen(true)}>Show Toast</button>
      <button onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
        Toggle Theme
      </button>

      <Toast.Provider>
        <Toast.Root
          open={open}
          onOpenChange={setOpen}
          duration={3000}
          css={toastStyles(theme)}
        >
          <Toast.Title>Notification</Toast.Title>
          <Toast.Description>This is a message!</Toast.Description>
          <Toast.Action altText="Close">
            <button onClick={() => setOpen(false)}>Close</button>
          </Toast.Action>
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </>
  );
};

export default ToastComponent;
