import { HUD, Toast, ToastHandle } from "@/components/Toast";
import ToastComponent from "@/components/Toast/toastComp";
import { Layout } from '@/components/Layout/layout';
import React from 'react';

const Card = () => {
  const [open, setOpen] = React.useState(false);
  const savedRef = React.useRef<ToastHandle>(null);
  return (
    <Layout>
      {/* <button 
        onClick={() => savedRef.current?.publish()}
      >
        Toggle HUD
      </button>
      <Toast ref={savedRef}>Saved successfully!</Toast> */}
      <ToastComponent />
    </Layout>
  );
};
export default Card;