import React from 'react';
import './404.less';
import { Layout } from '@/components/Layout/layout';
const NotFound: React.FC = () => {
  return (
    <Layout>
      <div className="not-found">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
    </Layout>
  );
};

export default NotFound;
