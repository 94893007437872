/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Layout } from '@/components/Layout/layout';
import {Box, Flex, Grid, Heading, Text, Button, Strong} from "@radix-ui/themes";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const GetOrderNumDetail = () => {
    const listStyle = css`
  list-style-type: disc;
  padding-left: 20px;

  li {
    margin-bottom: 8px;
  }
`;
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackClick = () => {
        navigate(-1); // 返回上一个页面
    };

    return (
        <>
            <Layout>

                <Flex width="100%" minHeight="100vh" justify={'center'} py={{ initial: '7', xs: '9', sm: '100px' }}>

                <Box px={{ initial: '4' }} width={{
                    initial: '90%',
                    sm: '80%',
                    xl: '1280px',
                }} >
                    <Button onClick={handleBackClick} mb={'9'}> Back </Button>
                    <Heading >
                        How to gat my Order Number?
                    </Heading>
                    <Box pt={"6"}>
                       <Grid columns="1" gap="7">
                           <Grid columns="1" gap="3">
                               <Heading size={'4'} >
                                   1.Check Your Order Confirmation Email
                               </Heading>
                               <Text size={'3'}>
                                   After completing your payment, you’ll receive an order confirmation email. Your order number is located after the "#" in the email subject line.
                               </Text>
                           </Grid>
                           <Grid columns="1" gap="4">
                               <Heading size={'4'}>
                                   2.Use Your PayPal Invoice ID
                               </Heading>
                               <ul css={listStyle}>
                                   <li>
                                       <Text size={'3'}>
                                            <Strong>PayPal Account:</Strong> Log in to your PayPal account and find the
                                           relevant transaction; the invoice ID is usually listed in the transaction
                                           details.
                                       </Text>
                                   </li>
                                   <li>
                                       <Text size={'3'}>
                                           <Strong>Email Confirmation:</Strong> Look for the transaction confirmation
                                           email from PayPal; the invoice ID is typically mentioned in that email.
                                       </Text>
                                   </li>
                               </ul>
                           </Grid>

                       </Grid>
                    </Box>
                </Box>
                </Flex>

            </Layout>
        </>
    );
};

// Feedback.Layout = Layout;

export default GetOrderNumDetail;