import { useState, useRef, useLayoutEffect, Dispatch, SetStateAction, useCallback} from 'react';
import { isBrowser, noop } from './misc/util';

type parserOptions<T> =
    | {
    raw: true;
}
    | {
    raw: false;
    serializer: (value: T) => string;
    deserializer: (value: string) => T;
};


const useSessionStorage = <T>(
    key: string,
    initialValue?: T,
    options?: parserOptions<T>
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {
    if (!isBrowser) {
        return [initialValue as T, noop, noop];
    }

    if (!key) {
        throw new Error('useSessionStorage key may not be falsy');
    }

    const deserializer = options
        ? options.raw
            ? (value: string) => value
            : options.deserializer
        : JSON.parse;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const initializer = useRef((key: string) => {
        try {
            const serializer = options ? (options.raw ? String : options.serializer) : JSON.stringify;

            const sessionStorageValue = sessionStorage.getItem(key);
            if (sessionStorageValue !== null) {
                return deserializer(sessionStorageValue);
            } else {
                initialValue && sessionStorage.setItem(key, serializer(initialValue));
                return initialValue;
            }
        } catch {
            // If user is in private mode or has storage restriction
            // sessionStorage can throw. JSON.parse and JSON.stringify
            // can throw, too.
            return initialValue;
        }
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState<T | undefined>(() =>  initializer.current(key));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => setState(initializer.current(key)), [key]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
        (valOrFunc) => {
            try {
                const newState =
                    typeof valOrFunc === 'function' ? (valOrFunc as Function)(state) : valOrFunc;
                if (typeof newState === 'undefined') return;
                let value: string;

                if (options)
                    if (options.raw)
                        if (typeof newState === 'string') value = newState;
                        else value = JSON.stringify(newState);
                    else if (options.serializer) value = options.serializer(newState);
                    else value = JSON.stringify(newState);
                else value = JSON.stringify(newState);

                sessionStorage.setItem(key, value);
                setState(deserializer(value));
            } catch {
                // If user is in private mode or has storage restriction
                // sessionStorage can throw. Also JSON.stringify can throw.
            }
        },
        [key, setState]
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const remove = useCallback(() => {
        try {
            sessionStorage.removeItem(key);
            setState(undefined);
        } catch {
            // If user is in private mode or has storage restriction
            // sessionStorage can throw.
        }
    }, [key, setState]);

    return [state, set, remove];
};

export default useSessionStorage;