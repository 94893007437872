import { Select } from '@radix-ui/themes';
import React, { forwardRef, ForwardRefRenderFunction, useEffect } from 'react';
import { UseFormRegister, FieldValues, Controller, Control } from 'react-hook-form';


export interface OptionValue {
  value: string;
  label: string;
}
interface GroupedOption {
  name: string;
  items: OptionValue[];
}

type Options = OptionValue[] | GroupedOption[];

export interface ZSelectProps {
  name: string;
  defaultValue?: string;
  variant?: 'classic' | 'surface' | 'soft' | 'ghost';
  options?: Options;
  placeholder?: string;
  onValueChange?: (value: string) => void;
  value?: string;
}

export interface SelectHandle {
  reset: () => void;
}

const ZSelect: React.FC<ZSelectProps> = ({
  name,
  value,
  defaultValue,
  variant = 'surface',
  options,
  placeholder,
  // register,
  onValueChange,
}) => {
  // const ZSelect = React.forwardRef<SelectHandle, ZSelectProps>(({ name, defaultValue, value, onValueChange, variant = 'surface', placeholder, options }, ref) => {
  function selectTriggerVariantToSelectContentVariant(
    triggerVariant: 'classic' | 'surface' | 'soft' | 'ghost',
  ) {
    if (triggerVariant === 'soft' || triggerVariant === 'ghost') return 'soft';
    return 'solid';
  }
  const isGrouped = (options: Options): options is GroupedOption[] => {
    return (options as GroupedOption[])[0]?.items !== undefined;
  };
  // const [value, setValue] = React.useState<string | undefined>(defaultValue); 
  // React.useImperativeHandle(ref, () => ({
  //   reset: () => {
  //     // @ts-ignore
  //     // ref.current.select();
  //     console.log('reset');
  //     setValue(defaultValue);
  //   },
  // }));
  useEffect(() => {
    console.log('value', value);
  }, [value]);
  return (
    <Select.Root
      name={name}
      // {...(register ? register(name) : { name: name })}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      value={value}
      // @ts-ignore
      // ref={ref}
    >
      <Select.Trigger variant={variant} placeholder={placeholder} />
      <Select.Content variant={selectTriggerVariantToSelectContentVariant(variant)}>
        {options && isGrouped(options) ? (
          options.map((group, groupIndex) => (
            <>
              {groupIndex > 0 && <Select.Separator />}
              <Select.Group>
                <Select.Label>{group.name}</Select.Label>
                {group.items.map(({ label, value }) => (
                  <Select.Item key={value} value={value}>
                    {label}
                  </Select.Item>
                ))}
              </Select.Group>
            </>
          ))
        ) : (
          <Select.Group>
            {options &&
              options.map(({ label, value }) => (
                <Select.Item key={value} value={value}>
                  {label}
                </Select.Item>
              ))}
          </Select.Group>
        )}
      </Select.Content>
    </Select.Root>
  );
};

 
export { ZSelect };
