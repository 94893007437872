import { Select } from '@radix-ui/themes';
import React, { forwardRef } from 'react';
import { FormField, FormFieldProps } from './FormField';
import { MarginProps } from '@radix-ui/themes/dist/esm/props/margin.props.d.ts';
import { UseFormRegister, FieldValues, Controller, Control } from 'react-hook-form';
import { ZSelect, ZSelectProps } from './Select';

export type FormSelectProps<T extends FieldValues> = Omit<FormFieldProps, 'children' | 'register'> &
  ZSelectProps & {
    control: Control<T, any>;
  };

const FormSelect = <T extends FieldValues>({
  name,
  label,
  validations,
  options,
  defaultValue,
  placeholder,
  control,
  ...props
}: FormSelectProps<T>) => {
  return (
    <FormField name={name+"formselect"} label={label} validations={validations}>
    <Controller
    // @ts-ignore
      name={name}
      control={control}
      render={({ field }) => {
        const { 
          ref,
          onChange,
          value,
           ...rest } = field; // removes ref 
        return (
          // <FormField name={name + 'formselect'} label={label} validations={validations} {...props}>
            <ZSelect
              name={name}
              onValueChange={onChange}
              variant="soft"
              // defaultValue={defaultValue}
              options={options}
              placeholder={placeholder}
              value={value}
              // {...rest}
            />
          // </FormField>
        );
      }} 
    />
    </FormField>
  );
};

export { FormSelect };
