import {Box, Text, TextField, Flex, HoverCard} from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { FormMessageProps } from '@radix-ui/react-form';
import { MarginProps } from '@radix-ui/themes/props';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import React from 'react';
import { InfoCircledIcon } from "@radix-ui/react-icons";

export type FormItemRegister<T extends FieldValues>  = {
  register: UseFormRegister<T>; 
};
export type FormFieldProps  = MarginProps & {
  name: string;
  label: string | React.ReactNode;
  validations?: {
    name?: string;
    match?: FormMessageProps['match'];
    forceMatch?: boolean;
    label: string | React.ReactNode;
  }[];
  children?: React.ReactNode; 
  required?: boolean;
  tooltip?: string | React.ReactNode;
};

const Tooltip: React.FC<   string | React.ReactNode > = ( tooltip ) => {
    return typeof tooltip === 'string' ? (
        <HoverCard.Root>
            <HoverCard.Trigger>
                <InfoCircledIcon />
            </HoverCard.Trigger>
            <HoverCard.Content sideOffset={5} side="top" align="center">
                <Box style={{ padding: 5, backgroundColor: 'white', borderRadius: 5 }}>
                    <Text size="1">{tooltip}</Text>
                </Box>
            </HoverCard.Content>
        </HoverCard.Root>
    ):(
        tooltip
    )
};

export const FormField: React.FC<FormFieldProps> = ({ name, label, validations, children, required = true, tooltip }) => {
  return (
    <Form.Field name={name}>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        <Form.Label>
            <Flex gap="4">
          {typeof label === 'string' ? (
            <Flex>
            <Text
              weight={'bold'}
              size={{
                initial: '2',
                xs: '3',
                sm: '4',
                md: '5',
              }}
            >
              {label}
            </Text>
            { required && <Box pl={'1'} asChild>
            <Text
              color={'red'}
              weight={'bold'} 
              size={{
                initial: '2',
                xs: '3',
                sm: '4',
                md: '5',
              }}
            >
              *
            </Text>
            </Box>}
            </Flex>
          ) : (
            <Flex>
            label
            { required && <Box pl={'1'} asChild>
            <Text
              color={'red'}
              weight={'bold'} 
              size={{
                initial: '2',
                xs: '3',
                sm: '4',
                md: '5',
              }}
            >
              *
            </Text>
            </Box>}
            </Flex>
          )}
            {tooltip && (
                Tooltip(tooltip)
            )}
            </Flex>
        </Form.Label>
        {validations?.map((validation, index) => (
          <Form.Message
            key={index}
            match={validation.match}
            name={validation.name}
            forceMatch={validation.forceMatch}
          >
            {typeof validation.label === 'string' ? (
              <Text
                weight={'medium'}
                color="orange"
                size={{
                  initial: '1',
                  xs: '2',
                  sm: '3',
                  md: '4',
                }}
              >
                {validation.label}
              </Text>
            ) : (
              validation.label
            )}
          </Form.Message>
        ))}
      </div>
      <Box mb="3" />
      <Form.Control asChild>{children}</Form.Control>
      <Box mb="5" />
    </Form.Field>
  );
};
