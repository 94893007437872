/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { ThemeProvider } from 'next-themes';
import { Theme } from '@radix-ui/themes';
import Background from '../Background/Background';
import BackgroundImage from '../Background/BackgroundImage';
import * as Toast from '@radix-ui/react-toast';

const tealBackgroundImageStyle = {
  '--color-background-image-base': 'var(--sage-1)',
  '--color-background-image-accent-1': 'var(--teal-a7)',
  '--color-background-image-accent-2': 'var(--mint-7)',
  '--color-background-image-accent-3': 'var(--green-9)',
  '--color-background-image-accent-4': 'var(--sky-5)',
  '--color-background-image-accent-5': 'var(--crimson-3)',
  '--color-background-image-accent-6': 'var(--mint-a5)',
  '--color-background-image-accent-7': 'var(--teal-5)',
} as React.CSSProperties;

export function Layout({ children }: React.PropsWithChildren<{}>) {
  const imgCss = css`
    --color-background-image-base: var(--color-background);
    --color-background-image-accent-1: var(--mint-a7);
    --color-background-image-accent-2: var(--mint-6);
    --color-background-image-accent-3: var(--mint-9);
    --color-background-image-accent-4: var(--mint-5);
    --color-background-image-accent-5: var(--mint-1);
    --color-background-image-accent-6: var(--mint-a5);
    --color-background-image-accent-7: var(--mint-5);
  `;
  return (
    <div>
      <ThemeProvider attribute="class">
        <Theme
          accentColor="mint"
          grayColor="gray"
          panelBackground="translucent"
          scaling="100%"
          radius="medium"
        >
          <main
            css={css`
              display: flex;
              position: relative;
              flex-direction: column;
              min-height: 100vh;

              overflow: hidden;
            `}
          >
            <Toast.Provider swipeDirection="right">
            <Background>
              <BackgroundImage
                // css={imgCss}
                style={tealBackgroundImageStyle}
                id="4"
              />
            </Background>
            {children}
            </Toast.Provider>
          </main>
        </Theme>
      </ThemeProvider>
    </div>
  );
}
