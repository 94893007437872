import { createRoot } from 'react-dom/client';
// import App from '@/pages/card';
import App from '@/pages/app';
import Root from '@/router/root';
import '@/pages/index.css';
import '@radix-ui/themes/styles.css';

const container = document.querySelector('#root') as Element;
// const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);
