import { RouteObject } from 'react-router-dom';
import Feedback from '@/pages/feedback';
import NotFound from '@/pages/notFound/404';
import Card from '@/pages/card';
import GetOrderNumDetail from '@/pages/GetOrderNum';
import DeviceTypeCheckerByMouse from "@/pages/demo/DeviceTypeCheckerByMouse";
import DeviceNavigatorInfo from "@/pages/demo/DeviceNavigatorInfo";
import SubmitSuccess from '@/pages/success/SubmitSuccess';

export const routes: RouteObject[] = [
  {
    path: '/', 
    element: <Feedback />,
    errorElement: <NotFound />,
  },
  {
    path: '/fetchOrderNum',
    element: <GetOrderNumDetail />,
  },
  {
    path: '/submitSuccess',
    element: <SubmitSuccess />,
  },
  {
    path: '/deviceChecker',
    element: <DeviceTypeCheckerByMouse />,
  },
  {
    path: '/deviceNavigator',
    element: <DeviceNavigatorInfo />,
  },
  {
    path: '/toast',
    element: <Card />,
  },
];
