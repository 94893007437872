import { TextField } from '@radix-ui/themes';
import { FormField, FormFieldProps, FormItemRegister } from './FormField';
import { UseFormRegister, FieldValues } from 'react-hook-form';

export type FormTextProps<T extends FieldValues> = Omit<FormFieldProps, 'children'> & {
  type?: 'date' | 'datetime-local' | 'email' | 'hidden' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week';
  placeholder?: string;
} & FormItemRegister<T>;

export const FormText = <T extends FieldValues>({
  name,
  label,
  type = 'text',
  placeholder = '',
  validations,
  register,
  ...props
}: FormTextProps<T>) => {
  return (
    <FormField name={name} label={label} validations={validations} {...props}>
      <TextField.Root
        type={type}
        size="3"
        mt="3"
        variant="soft"
        placeholder={placeholder}
        // @ts-ignore
        {...register(name)}
      />
    </FormField>
  );
};
