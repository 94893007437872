import { TextArea } from '@radix-ui/themes';
import { FormField, FormFieldProps, FormItemRegister } from './FormField';
import { UseFormRegister, FieldValues } from 'react-hook-form';

export type  FormTextAreaProps<T extends FieldValues> =  Omit<FormFieldProps, 'children'> & {
  placeholder?: string;
} & FormItemRegister<T>;

export const FormTextArea = <T extends FieldValues>({
  name,
  label,
  validations,
  placeholder,
  register,
  ...props
}: FormTextAreaProps<T>) => {
  return (
    <FormField name={name} label={label} validations={validations} {...props}>
      <TextArea
        variant={'soft'}
        placeholder={placeholder}
        // @ts-ignore
        {...register(name)}
      />
    </FormField>
  );
};
